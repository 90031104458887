<app-admin-nav></app-admin-nav>
<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="container" style="margin-top: 40px; padding: 30px">
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Add Package
  </h1>
  <hr class="line" />
  <div class="form-group">
    <label for="oldPassword">Price</label>
    <input type="number" class="form-control" [(ngModel)]="price" />
  </div>
  <div class="form-group mt-3">
    <label for="oldPassword">Net Price</label>
    <input type="number" class="form-control" [(ngModel)]="net_price" />
  </div>
  <div class="form-group mt-3">
    <label for="oldPassword">Description</label>
    <input type="text" class="form-control" [(ngModel)]="description" />
  </div>
  <div class="form-group mt-3">
    <label for="oldPassword">Cards Limit (Enter 0 for unlimited cards)</label>
    <input type="number" class="form-control" [(ngModel)]="limit" />
  </div>
  <div class="form-group mt-3">
    <label for="expire_in">Expire In</label>
    <select
      id="expire_in"
      name="expire_in"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="expire_in"
    >
      <option value="Free">Free</option>
      <option value="6 month">6 Months</option>
      <option value="1 month">1 Month</option>
      <option value="1 year">1 Year</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="logo">Logo</label>
    <select
      id="logo"
      name="logo"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="logo"
    >
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="product_image">Product Image</label>
    <select
      id="product_image"
      name="product_image"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="product_image"
    >
      <option value="No image">No Image</option>
      <option value="1 image">1 Image</option>
      <option value="3 image">3 Image</option>
      <option value="unlimited">Unlimited</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="social_media_listing"
      >Social Media Listing + Communication</label
    >
    <select
      id="social_media_listing"
      name="social_media_listing"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="social_media_listing"
    >
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="Website">Website</label>
    <select
      id="Website"
      name="Website"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="website"
    >
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="umyoutube">Youtube Videos</label>
    <select
      id="umyoutube"
      name="umyoutube"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="videos"
    >
      <option value="No video">No Video</option>
      <option value="1 video link">1 Video Link</option>
      <option value="2 video link">2 Video Link</option>
      <option value="3 video link">3 Video Link</option>
      <option value="Unlimited(General)">Unlimited (General)</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="umyoutube">Vimeo Videos</label>
    <select
      id="vimeo"
      name="vimeo"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="vimeo"
    >
      <option value="No video">No Video</option>
      <option value="1 video link">1 Video Link</option>
      <option value="2 video link">2 Video Link</option>
      <option value="3 video link">3 Video Link</option>
      <option value="Unlimited(General)">Unlimited (General)</option>
    </select>
  </div>
  <div class="form-group mt-3">
    <label for="umyoutube">Umyotube Videos</label>
    <select
      id="umyoutube"
      name="umyoutube"
      class="form-select mt-2"
      aria-label="Default select example"
      [(ngModel)]="umyoutube"
    >
      <option value="No video">No Video</option>
      <option value="1 video link">1 Video Link</option>
      <option value="2 video link">2 Video Link</option>
      <option value="3 video link">3 Video Link</option>
      <option value="Unlimited(General)">Unlimited (General)</option>
    </select>
  </div>

  <button class="btn btn-primary rounded-corner mt-3" (click)="addPackage()">
    Add Package
  </button>
</div>
