<section id="header" style="background: #68292c !important">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a [routerLink]="['/']" class="navbar-brand">
          <!-- <h1 class="text-white display-6">UMYO</h1> -->
          <img
            src="assets/proliving/pro_liv.jpeg"
            class="img-fluid rounded-circle"
            style="width: 4rem; height: 4rem"
          />
        </a>
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
            <a [routerLink]="['/']" class="nav-item nav-link">Home</a>
            <a href="https://pltalk.site/welcome" class="nav-item nav-link"
              >PLTalk</a
            >

            <a href="https://vibetalktv.com/" class="nav-item nav-link"
              >Podcast</a
            >
            <a href="https://uchatyes.com/" class="nav-item nav-link"
              >ChatGpt</a
            >
            <a href="https://pltweet.site/guest" class="nav-item nav-link"
              >Pltweet</a
            >
            <a [routerLink]="['/cf']" class="nav-item nav-link"
              >Card Features</a
            >
            <a [routerLink]="['/package']" class="nav-item nav-link"
              >View Services</a
            >
            <a [routerLink]="['/ai']" class="nav-item nav-link"
              >Additional Features</a
            >
          </div>
          <div class="d-flex m-3 ms-auto">
            <!-- <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">UMYO</p> -->
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-5 pb-3 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/']">Home</a>
              </li>
              <li class="nav-item">
                <a href="https://pltalk.site/welcome" class="nav-link"
                  >PLTalk</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://vibetalktv.com/">Podcast</a>
              </li>
              <li class="nav-item">
                <a href="https://uchatyes.com/" class="nav-link">ChatGpt</a>
              </li>
              <li class="nav-item">
                <a href="https://pltweet.site/guest" class="nav-link"
                  >Pltweet</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cf']">Card Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/package']"
                  >View Services</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ai']"
                  >Additional Features</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- Hero Start -->
</section>

<!------- tab section end ---------->

<section id="acc">
  <div class="container my-5">
    <h2 class="text-center">
      Membership / Business / I AM Pledge Card Features
    </h2>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <ul>
          <li>Cover Image</li>
          <li>About Us</li>
          <li>VPN Webinar</li>
          <li>Social: FB, Twitter, LinkedIn, You tube, Instagram, Pinterest</li>
          <li>Communication</li>
          <li>Skype ID</li>
          <li>Whats App</li>
          <li>Snapchat</li>
          <li>Videos</li>
          <li>Scheduling</li>
          <li>Link Button to website</li>
          <li>Live Chats</li>
          <li>Follow My Card</li>
          <li>Save My Contacts</li>
          <li>Friend Request</li>
          <li>Audio/Images</li>
          <li>Search Biz Card, Friends, Photo & Video</li>
          <li>Library of Card Templates to Choose From</li>
          <li>Social Networking platform (Backend)</li>
          <li>➔ QR Code</li>
          <li>Add My Logo</li>
          <li>Upgrade</li>
          <li>Share My Card</li>
          <li>Save Customer Information</li>
          <li>See how many times the card was shared and viewed.</li>
        </ul>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</section>
<!-- Featurs Section End -->

<!-- Footer Start -->
<div
  class="container-fluid bg-dark text-white-50 footer"
  style="background: #68292c !important; height: 100% !important"
>
  <div class="container py-5">
    <div class="row g-4">
      <div class="col-lg-4 col-md-6">
        <h4 class="text-light"><b>Contact us</b></h4>
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" href="">Privacy Policy</a>
          <a class="btn-link" href="">Refund Policy</a>
          <a class="btn-link" href="">Terms Of Service</a>
          <a class="btn-link" href="">Terms of Sales</a>
          <a class="btn-link" href="">UMYO Profile</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-item">
          <h4 class="text-light mb-3">LET'S CONNECT</h4>
          <p class="mb-4">
            Subscribe to stay up to date on all the latest Pro-Living features,
            products, stories, and more. You can also contact us at
            contact@prolivingbiz.com.
          </p>
          <div class="position-relative mx-auto">
            <input
              class="form-control border-0 py-2 w-100"
              type="text"
              placeholder="Enter Your Email"
            />
            <button
              type="submit"
              class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
              style="top: 0; right: 0"
            >
              Sent
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="d-flex justify-content-center pt-3">
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="btn btn-outline-secondary btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
      <p class="text-white text-center">
        <a href="#" style="text-decoration: none; color: white"
          ><i class="fas fa-copyright text-light me-2"></i>2023 Pro-Living</a
        >, All right reserved.
      </p>
    </div>
  </div>
</div>
