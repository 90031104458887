<!-- Navbar start -->
<div style="overflow-x: hidden">
  <section id="header" style="background: #68292c !important">
    <div class="overlay"></div>
    <div class="container-fluid">
      <div class="container px-0">
        <nav
          class="navbar navbar-expand-xl"
          style="border-bottom: 2px solid white"
        >
          <a [routerLink]="['/']" class="navbar-brand">
            <!-- <h1 class="text-white display-6">UMYO</h1> -->
            <img
              src="assets/proliving/pro_liv.jpeg"
              class="img-fluid rounded-circle"
              style="width: 4rem; height: 4rem"
            />
          </a>
          <button
            class="navbar-toggler py-2 px-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="fa fa-bars text-primary"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav d-lg-none">
              <a [routerLink]="['/']" class="nav-item nav-link">Home</a>
              <a href="https://pltalk.site/welcome" class="nav-item nav-link"
                >PLTalk</a
              >

              <a href="https://vibetalktv.com/" class="nav-item nav-link"
                >Podcast</a
              >
              <a href="https://uchatyes.com/" class="nav-item nav-link"
                >ChatGpt</a
              >
              <a href="https://pltweet.site/guest" class="nav-item nav-link"
                >Pltweet</a
              >
              <a [routerLink]="['/cf']" class="nav-item nav-link"
                >Card Features</a
              >
              <a [routerLink]="['/package']" class="nav-item nav-link"
                >View Services</a
              >
              <a [routerLink]="['/ai']" class="nav-item nav-link"
                >Additional Features</a
              >
            </div>
            <div class="d-flex m-3 ms-auto">
              <!-- <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">UMYO</p> -->
              <button
                class="btn-search btn border border-white btn-md-square rounded-circle me-4"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
              >
                <i class="fas fa-search text-white"></i>
              </button>
              <a href="#" class="position-relative me-4 my-auto">
                <i class="fa fa-shopping-cart text-white fa-2x"></i>
              </a>
              <a href="#" class="my-auto">
                <button class="btn btn-primary bg-white text-dark border-0 p-2">
                  Get Started
                </button>
              </a>
            </div>
          </div>
        </nav>
        <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
          <div class="container-fluid p-0">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-5 pb-3 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/']">Home</a>
                </li>
                <li class="nav-item">
                  <a href="https://pltalk.site/welcome" class="nav-link"
                    >PLTalk</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://vibetalktv.com/">Podcast</a>
                </li>
                <li class="nav-item">
                  <a href="https://uchatyes.com/" class="nav-link">ChatGpt</a>
                </li>
                <li class="nav-item">
                  <a href="https://pltweet.site/guest" class="nav-link"
                    >Pltweet</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/cf']">Card Features</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/package']"
                    >View Services</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/ai']"
                    >Additional Features</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <!-- Navbar End -->

    <!-- Modal Search Start -->
    <div
      class="modal fade"
      id="searchModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content rounded-0">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Search by keyword
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body d-flex align-items-center">
            <div class="input-group w-75 mx-auto d-flex">
              <input
                type="search"
                class="form-control p-3"
                placeholder="keywords"
                aria-describedby="search-icon-1"
              />
              <span id="search-icon-1" class="input-group-text p-3"
                ><i class="fa fa-search"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Search End -->

    <!-- Hero Start -->
  </section>
  <!--  <section id="page-top" class="lft-hero">
  <div class="lft-video">
    
    <video src="../video.mp4" autoplay muted loop style="width:100%; height:100%;"></video>
    <div class="overlay"></div>
  </div>
  
            <div class="container py-5">
                <div class="row g-5 align-items-center">
                    <div class="col-md-12 col-lg-7">
                        <div class="page-width">
    <div class="lft-hero__content">
                        <h1 class="mb-3">Pro-Living Connects</h1>
                        <p class="mb-5">Pro-Living Connects introduces an innovative networking solution exclusively for Community.</p>
                        <div class="moretext-he" style="display:none;">
                        <p class="mb-5">Our groundbreaking app unites Pro-Living Connects, logistics professionals, community, business owners, organizations, training institutions, and affiliated businesses on a specialized platform, dedicated to advancing the careers of youth and adults in the industry and promoting growth. Pro-Living Connect is the first of its kind, enabling Pro-Living community members and professionals to network with fellow Pro-Living members, industry experts, and businesses while showcasing their skills and expertise, receiving the recognition and support they rightfully deserve.</p>
                    </div>
                    <a class="moreless-button-he text-white btn btn-dark" style="text-decoration: none; " href="#he">Read more</a>
                    </div>
                </div>
            </div>
                    <div class="col-md-12 col-lg-5 px-5">
                     <div class="grid__item medium-up--one-half">
            <div class="videos__wrapper">
                <div class="videos__wrapper--video">
                   <iframe width="560" height="315" src="https://www.youtube.com/embed/UJ6bm5ZEOkc?si=0TDE2JWg34RYzxvz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>      
          </div>   
         </div>
        </div>
    </div>
</section> -->
  <!-- <section id="logo-slider" style="background:#68292C!important;">
        <div class="container-fluid mt-5">
                    <h1 class="text-center text-white mb-5">The “UMYO” All In One Networking App</h1>
                <div class="slider slider-1">
                          <div class="mb-5"><img src="assets/aboutimg/logo2/blackwall.png"></div>
                          <div><img src="assets/aboutimg/logo2/events.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo2.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo3.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo4.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo5.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo6.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo7.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo8.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo9.png"> </div>
                          <div><img src="assets/aboutimg/logo2/logo10.png"></div>
                </div>

                <div class="slider slider-2 mb-4" dir="rtl">
                    <div><img src="assets/aboutimg/logo2/logo11.png"></div>
                        <div class="mb-5"><img src="assets/aboutimg/logo2/logo12.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo13.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo14.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo15.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo16.png"></div>
                          <div><img src="assets/aboutimg/logo2/pledge.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo9.png"> </div>
                          <div><img src="assets/aboutimg/logo2/umyo_market.png"></div>
                          <div><img src="assets/aboutimg/logo2/logo8.png"></div>
                          <div><img src="assets/aboutimg/logo2/umyodate.png"></div>
                </div>

        </div>
        </section> -->
  <!-- Hero End -->
  <!------- Prolivlling start-------->
  <section id="main">
    <div class="">
      <img
        src="assets/aboutimg/pro_liv.png"
        class="img-fluid simg d-none"
        alt=""
      />
      <div class="row" style="padding-left: 50px; padding-right: 50px">
        <div class="col-md-7">
          <div class="">
            <img src="assets/aboutimg/pro_liv.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-md-1"></div>

        <div class="col-md-4">
          <div class="main-content text-center">
            <h3>
              Discover the knowledge, gain wisdom, to navigate the workforce
              like a Pro!
            </h3>
            <p>
              Empower entertainers to drive social and economic change while
              navigating life after their careers.
            </p>
            <button type="button" class="btn btn-dark">GET STARTED NOW</button>
          </div>
        </div>
      </div>

      <!------- Proliving end ---------->
      <div
        class="container-fluid featurs pt-3 mt-3 mb-5"
        style="padding-left: 50px; padding-right: 50px"
      >
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-6 p-3">
              <h2 style="color: #4390b3">About Pro Living</h2>
              <h4 class="my-4">Our Mission</h4>
              <p class="my-4">
                "Pro Living: Empowering athletes for life beyond sports. We
                create a vibrant community, leveraging athlete influence to
                connect with diverse audiences and provide financial support
                during transitions. Founded by experts across education,
                non-profits, and for-profits, we address industry challenges,
                aiding companies in growth, credibility, and visibility. Beyond
                jobs, we empower retirees to inspire purposeful living. Join our
                global village, fostering inspiration and leaving a legacy of
                positive change."
              </p>
              <h4 class="my-4">Our Services</h4>
              <p class="my-4">
                "At Pro Living, we are an athlete-fueled company dedicated to
                making a lasting social impact. Our dynamic platform serves as a
                village square, bringing together athletes, business partners,
                and nonprofits to inspire and connect with our target audience.
                We passionately believe in the transformative power of knowledge
                and wisdom. By curating valuable insights, we empower our
                community to make informed choices, be it engaging with products
                or services, exploring career opportunities, or contributing to
                the growth of the workforce. Join us in this journey of
                empowerment and connection, where each action propels positive
                change on a global scale."
              </p>
              <button type="submit" class="btn btn-dark my-4">
                View Services
              </button>
              <h4 class="my-4">Our Story</h4>
              <p class="my-4">
                At Pro Living, our journey began with a mission: to provide
                professional athletes a seamless transition into their next life
                phase beyond sports. We envisioned a platform where athletes
                could leverage their influence and principles to inspire
                communities while exploring diverse career paths. Our unique
                approach not only supports athletes financially during this
                transitional period but also creates a positive ripple effect in
                society.
              </p>
              <p class="my-4">
                Rooted in the revival of the village mentality, Pro Living
                builds a community of like-minded individuals passionate about
                serving others. We are not just an athlete-fueled company; we
                are a catalyst for significant social impact. Our dynamic
                platform, a modern village square, brings together athletes,
                businesses, and nonprofits to inspire and connect with our
                target audience.
              </p>
              <p class="my-4">
                We understand the pain points of different industries, thanks to
                our founders' extensive experience in education, non-profits,
                and for-profit sectors. Recognizing the challenges companies
                face in workforce growth, credibility, and visibility, we
                provide third-party services to support their journey. Our
                vision extends beyond a job opportunity; it's about empowering
                retirees to influence, build communities, and inspire others to
                embrace a purposeful life daily, not just exist.
              </p>
              <p class="my-4">
                Pro Living is more than a company; it's a village—a space where
                people come together to learn, grow, and support each other.
                Join us in creating a community that transcends boundaries,
                fosters inspiration, and builds a legacy of positive change.
                Together, we shape a world where every individual can live life
                to the fullest.
              </p>
            </div>
            <div class="col-lg-6">
              <img
                src="assets/aboutimg/pic.jpg"
                class="img-fluid"
                style="width: auto; height: 869px; margin: auto; display: block"
              />
            </div>
          </div>
        </div>
      </div>

      <section id="header" style="background: #68292c !important">
        <div class="container-fluid srice py-5">
          <div class="container py-5">
            <h1 class="text-white text-center mb-3" style="font-weight: 600">
              Search for Pro-Living and Community Pledges
            </h1>
            <div class="row">
              <div class="col-lg-5">
                <h4 class="mb-4 text-white">
                  Search For Pro-Living & Community Here
                </h4>
                <form [formGroup]="form2" class="text-center">
                  <div class="row">
                    <div class="col-lg-6 mb-4">
                      <input
                        type="name"
                        formControlName="selectedName"
                        class="form-control"
                        placeholder="Name"
                      />
                    </div>

                    <div class="col-lg-6 mb-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="selectedBusinessType"
                      >
                        <option selected value="" disabled>
                          Business Type
                        </option>
                        <option
                          [value]="type.business_type"
                          *ngFor="let type of businessType"
                        >
                          {{ type.business_type }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="selectedLocation"
                      >
                        <option selected value="" disabled>Location</option>
                        <option value="Business">Business</option>
                        <option value="CEO">CEO</option>
                        <option value="Corporate">Corporate</option>
                        <option value="Corporation">Corporation</option>
                        <option value="Exclusive">Exclusive</option>
                        <option value="Founder">Founder</option>
                        <option value="CFO">CFO</option>
                        <option value="COO">COO</option>
                        <option value="Home Owner">Home Owner</option>
                        <option value="Mall">Mall</option>
                        <option value="Mass">Mass</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Non Profit">Non Profit</option>
                        <option value="Office">Office</option>
                        <option value="Online">Online</option>
                        <option value="Partners">Partners</option>
                        <option value="Private">Private</option>
                        <option value="Retail and Consultants">
                          Retail and Consultants
                        </option>
                        <option value="Community Pledge">
                          Community Pledge
                        </option>
                        <option value="Store Front">Store Front</option>
                        <option value="Pop Up Shop">Pop Up Shop</option>
                        <option value="Vehicle">Vehicle</option>
                      </select>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <input
                        type="text"
                        formControlName="selectedCity"
                        class="form-control"
                        placeholder="City"
                      />
                    </div>

                    <div class="col-lg-6 mb-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="selectedStateType"
                      >
                        <option selected value="" disabled>
                          Select State Type
                        </option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div class="col-lg-6">
                      <h4 class="mb-4 text-white text-center">
                        I Pledge To Report Violent Crimes
                      </h4>
                    </div>
                    <div class="col-lg-6 mb-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="selectedRace"
                      >
                        <option selected value="" disabled>Race</option>
                        <option value="Nubians">Nubians</option>
                        <option value="African">African</option>
                        <option value="Native American">Native American</option>
                        <option value="Afro-Americans">Afro-Americans</option>
                        <option value="Asian">Asian</option>
                        <option value="Hispanic">Hispanic</option>
                        <option value="European">European</option>
                        <option value="Pacific Isalnder">
                          Pacific Isalnder
                        </option>
                        <option value="German">German</option>
                        <option value="Middle Eastern">Middle Eastern</option>
                        <option value="Casun">Casun</option>
                        <option value="Mixed Ethnicity">Mixed Ethnicity</option>
                        <option value="Vietnamese">Vietnamese</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        formControlName="selectedGender"
                      >
                        <option selected value="" disabled>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                      <button
                        type="submit"
                        class="btn btn-info text-white py-2 px-5 w-100"
                        (click)="searchCards()"
                        [disabled]="!form2.valid"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>

                <div class="bg-info rounded text-white py-2 px-3 my-5">
                  <p class="text-white mb-4">Cards:</p>
                  <p
                    class="text-white mb-4"
                    style="cursor: pointer"
                    [routerLink]="'/cards/share-card/' + card.id"
                    *ngFor="let card of cardsSearched"
                  >
                    {{ card.cardTitle }}
                  </p>
                </div>
              </div>
              <div class="col-lg-7 mt-5">
                <div class="content">
                  <!-- Nav pills -->
                  <ul class="nav nav-pills" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        (click)="checkRoute()"
                        [class.active]="showLoginTab"
                        data-toggle="pill"
                        href="#login"
                        >Login</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        (click)="checkRoute()"
                        [class.active]="showRegisterTab"
                        data-toggle="pill"
                        href="#regis"
                        >Register</a
                      >
                    </li>
                  </ul>

                  <!-- Tab panes -->
                  <div class="tab-content rounded p-4">
                    <div
                      id="login"
                      class="container tab-pane"
                      [class.active]="showLoginTab"
                    >
                      <form [formGroup]="form">
                        <div class="row">
                          <h1 class="text-center">UMYO For Pro-Living</h1>
                          <p class="text-center">
                            Register to Connect with Your Community
                          </p>
                          <br /><br /><br /><br /><br /><br />
                          <h2 class="text-info mb-3">Login</h2>
                          <div class="col-md-12 mb-5">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Email
                                <sup style="color: red; font-size: 20px">*</sup>
                              </label>
                              <input
                                type="email"
                                id="email"
                                class="form-control"
                                formControlName="email"
                                required
                              />
                              <div
                                *ngIf="
                                  form.get('email')?.hasError('required') &&
                                  form.get('email')?.touched
                                "
                                class="text-danger"
                              >
                                Email is required.
                              </div>
                              <div
                                *ngIf="
                                  form.get('email')?.hasError('email') &&
                                  form.get('email')?.touched
                                "
                                class="text-danger"
                              >
                                Invalid email format.
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mb-5">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Password<sup
                                  style="color: red; font-size: 20px"
                                  >*</sup
                                >
                              </label>
                              <input
                                type="password"
                                id="password"
                                class="form-control"
                                formControlName="password"
                                required
                              />
                              <div
                                *ngIf="
                                  form.get('password')?.hasError('required') &&
                                  form.get('password')?.touched
                                "
                                class="text-danger"
                              >
                                Password is required.
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 mb-3">
                            <div class="form-group">
                              <div
                                class="custom-control custom-checkbox custom-checkbox-lg"
                              >
                                <input
                                  style="width: 1rem; height: 1rem"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="ageCheckbox"
                                />
                                <label
                                  class="custom-control-label"
                                  for="ageCheckbox"
                                  style="
                                    font-size: 20px;
                                    font-weight: 600;
                                    margin-left: 10px;
                                  "
                                >
                                  Are you above 13 years old?
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group" style="padding-top: 10px">
                            <label
                              style="
                                font-size: 16px;
                                font-weight: 400;
                                text-decoration: underline;
                                cursor: pointer;
                              "
                              [routerLink]="['/forgot-password']"
                              >Forgot Password?
                            </label>
                          </div>
                        </div>

                        <button
                          (click)="openAgeModal('login')"
                          type="submit"
                          class="btn btn-info text-white mt-3 py-3 px-5"
                          style="margin: auto; display: block"
                          [disabled]="!form.valid"
                        >
                          Submit
                        </button>
                        <!-- <div class="bg-img">
          <img src="assets/img/illustrations-office.png"></div> -->
                      </form>
                    </div>

                    <div
                      id="regis"
                      class="container tab-pane"
                      [class.active]="showRegisterTab"
                    >
                      <form [formGroup]="form3">
                        <div class="row justify-content-center">
                          <h1 class="text-center">UMYO Pro</h1>
                          <div class="col-md-3 my-5">
                            <p class="text-center">starting at</p>
                            <h1 class="text-center">$5/mo</h1>
                            <p class="text-center">per agent</p>
                          </div>
                          <div class="col-md-1 my-5 d-none d-lg-block">
                            <div class="verticle"></div>
                          </div>
                          <div class="col-md-6 my-5">
                            <p>Includes:</p>
                            <li>40% off all UMYO products</li>
                            <li>3 free property listings per agent</li>
                            <li>Free standard CRM integrations</li>
                          </div>
                          <h2 class="mb-3 text-info">Register</h2>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Frist Name
                                <sup style="color: red; font-size: 20px"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="text"
                                formControlName="registerFirstName"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    !form3.get('registerFirstName')?.touched &&
                                    submitButtonClicked &&
                                    !form3.valid
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Last Name
                                <sup style="color: red; font-size: 20px">*</sup>
                              </label>
                              <input
                                type="text"
                                formControlName="registerLastName"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    !form3.get('registerLastName')?.touched &&
                                    submitButtonClicked &&
                                    !form3.valid
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Phone Number
                                <sup style="color: red; font-size: 20px"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="text"
                                formControlName="registerPhone"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    !form3.get('registerPhone')?.touched &&
                                    submitButtonClicked &&
                                    !form3.valid
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Email
                                <sup style="color: red; font-size: 20px"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="email"
                                formControlName="registerEmail"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    (!form3.get('registerEmail')?.touched ||
                                      !form3.get('registerEmail')?.valid) &&
                                    submitButtonClicked &&
                                    (!form3.valid ||
                                      form3.hasError('emailMismatch'))
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Confirm Email
                                <sup style="color: red; font-size: 20px"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="email"
                                formControlName="registerConfirmEmail"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    (!form3.get('registerConfirmEmail')
                                      ?.touched ||
                                      !form3.get('registerConfirmEmail')
                                        ?.valid) &&
                                    submitButtonClicked &&
                                    (!form3.valid ||
                                      form3.hasError('emailMismatch'))
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Password<sup
                                  style="color: red; font-size: 20px"
                                  >* </sup
                                ><sup style="color: red; font-size: 14px"
                                  >( Length Should be greater than 6 )</sup
                                >
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                required
                                formControlName="registerPassword"
                                [ngClass]="{
                                  'invalid-input':
                                    (!form3.get('registerPassword')?.touched ||
                                      !form3.get('registerPassword')?.valid) &&
                                    submitButtonClicked &&
                                    !form3.valid
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 mb-5">
                            <label style="font-size: 20px; font-weight: 600"
                              >Select Location<sup
                                style="color: red; font-size: 20px"
                                >*</sup
                              >
                            </label>
                            <select
                              formControlName="registerLocationType"
                              class="form-select"
                              aria-label="Default select example"
                              [ngClass]="{
                                'invalid-input':
                                  (!form3.get('registerLocationType')
                                    ?.touched ||
                                    !form3.get('registerLocationType')
                                      ?.valid) &&
                                  submitButtonClicked &&
                                  !form3.valid
                              }"
                            >
                              <option value="" disabled selected>
                                Please Select
                              </option>
                              <option value="Business">Business</option>
                              <option value="CEO">CEO</option>
                              <option value="Corporate">Corporate</option>
                              <option value="Corporation">Corporation</option>
                              <option value="Exclusive">Exclusive</option>
                              <option value="Founder">Founder</option>
                              <option value="CFO">CFO</option>
                              <option value="COO">COO</option>
                              <option value="Home Owner">Home Owner</option>
                              <option value="Mall">Mall</option>
                              <option value="Mass">Mass</option>
                              <option value="Mobile">Mobile</option>
                              <option value="Non Profit">Non Profit</option>
                              <option value="Office">Office</option>
                              <option value="Online">Online</option>
                              <option value="Partners">Partners</option>
                              <option value="Private">Private</option>
                              <option value="Retail and Consultants">
                                Retail and Consultants
                              </option>
                              <option value="Community Pledge">
                                Community Pledge
                              </option>
                              <option value="Store Front">Store Front</option>
                              <option value="Pop Up Shop">Pop Up Shop</option>
                              <option value="Vehicle">Vehicle</option>
                            </select>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <label style="font-size: 20px; font-weight: 600"
                              >Select State<sup
                                style="color: red; font-size: 20px"
                                >*</sup
                              >
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              formControlName="registerStateType"
                              [ngClass]="{
                                'invalid-input':
                                  (!form3.get('registerStateType')?.touched ||
                                    !form3.get('registerStateType')?.valid) &&
                                  submitButtonClicked &&
                                  !form3.valid
                              }"
                            >
                              <option value="" disabled selected>
                                Please Select
                              </option>
                              <option value="Alabama">Alabama</option>
                              <option value="Alaska">Alaska</option>
                              <option value="Arizona">Arizona</option>
                              <option value="Arkansas">Arkansas</option>
                              <option value="California">California</option>
                              <option value="Colorado">Colorado</option>
                              <option value="Connecticut">Connecticut</option>
                              <option value="Delaware">Delaware</option>
                              <option value="Florida">Florida</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Idaho">Idaho</option>
                              <option value="Illinois">Illinois</option>
                              <option value="Indiana">Indiana</option>
                              <option value="Iowa">Iowa</option>
                              <option value="Kansas">Kansas</option>
                              <option value="Kentucky">Kentucky</option>
                              <option value="Louisiana">Louisiana</option>
                              <option value="Maine">Maine</option>
                              <option value="Maryland">Maryland</option>
                              <option value="Massachusetts">
                                Massachusetts
                              </option>
                              <option value="Michigan">Michigan</option>
                              <option value="Minnesota">Minnesota</option>
                              <option value="Mississippi">Mississippi</option>
                              <option value="Missouri">Missouri</option>
                              <option value="Montana">Montana</option>
                              <option value="Nebraska">Nebraska</option>
                              <option value="Nevada">Nevada</option>
                              <option value="New Hampshire">
                                New Hampshire
                              </option>
                              <option value="New Jersey">New Jersey</option>
                              <option value="New Mexico">New Mexico</option>
                              <option value="New York">New York</option>
                              <option value="North Carolina">
                                North Carolina
                              </option>
                              <option value="North Dakota">North Dakota</option>
                              <option value="Ohio">Ohio</option>
                              <option value="Oklahoma">Oklahoma</option>
                              <option value="Oregon">Oregon</option>
                              <option value="Pennsylvania">Pennsylvania</option>
                              <option value="Rhode Island">Rhode Island</option>
                              <option value="South Carolina">
                                South Carolina
                              </option>
                              <option value="South Dakota">South Dakota</option>
                              <option value="Tennessee">Tennessee</option>
                              <option value="Texas">Texas</option>
                              <option value="Utah">Utah</option>
                              <option value="Vermont">Vermont</option>
                              <option value="Virginia">Virginia</option>
                              <option value="Washington">Washington</option>
                              <option value="West Virginia">
                                West Virginia
                              </option>
                              <option value="Wisconsin">Wisconsin</option>
                              <option value="Wyoming">Wyoming</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <label style="font-size: 20px; font-weight: 600"
                              >Business Type<sup
                                style="color: red; font-size: 20px"
                                >*</sup
                              >
                            </label>
                            <select
                              formControlName="registerBusinessType"
                              class="form-select"
                              aria-label="Default select example"
                              [ngClass]="{
                                'invalid-input':
                                  (!form3.get('registerBusinessType')
                                    ?.touched ||
                                    !form3.get('registerBusinessType')
                                      ?.valid) &&
                                  submitButtonClicked &&
                                  !form3.valid
                              }"
                            >
                              <option value="" disabled selected>
                                Please Select
                              </option>
                              <option
                                [value]="type.business_type"
                                *ngFor="let type of businessType"
                              >
                                {{ type.business_type }}
                              </option>
                            </select>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >City
                                <sup style="color: red; font-size: 20px"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="text"
                                formControlName="registerCityType"
                                class="form-control"
                                required
                                [ngClass]="{
                                  'invalid-input':
                                    !form3.get('registerCityType')?.touched &&
                                    submitButtonClicked &&
                                    !form3.valid
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <label style="font-size: 20px; font-weight: 600"
                              >Race<sup style="color: red; font-size: 20px"
                                >*</sup
                              >
                            </label>
                            <select
                              [ngClass]="{
                                'invalid-input':
                                  (!form3.get('registerRaceType')?.touched ||
                                    !form3.get('registerRaceType')?.valid) &&
                                  submitButtonClicked &&
                                  !form3.valid
                              }"
                              formControlName="registerRaceType"
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option value="" disabled selected>
                                Please Select
                              </option>

                              <option value="Nubians">Nubians</option>
                              <option value="African">African</option>
                              <option value="Native American">
                                Native American
                              </option>
                              <option value="Afro-Americans">
                                Afro-Americans
                              </option>
                              <option value="Asian">Asian</option>
                              <option value="Hispanic">Hispanic</option>
                              <option value="European">European</option>
                              <option value="Pacific Isalnder">
                                Pacific Isalnder
                              </option>
                              <option value="German">German</option>
                              <option value="Middle Eastern">
                                Middle Eastern
                              </option>
                              <option value="Casun">Casun</option>
                              <option value="Mixed Ethnicity">
                                Mixed Ethnicity
                              </option>
                              <option value="Vietnamese">Vietnamese</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <label style="font-size: 20px; font-weight: 600"
                              >Gender<sup style="color: red; font-size: 20px"
                                >*</sup
                              >
                            </label>
                            <select
                              [ngClass]="{
                                'invalid-input':
                                  (!form3.get('registerGenderType')?.touched ||
                                    !form3.get('registerGenderType')?.valid) &&
                                  submitButtonClicked &&
                                  !form3.valid
                              }"
                              required
                              formControlName="registerGenderType"
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option value="" disabled selected>
                                Please Select
                              </option>

                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          <div class="col-md-12 mb-3">
                            <div class="form-group">
                              <label style="font-size: 20px; font-weight: 600"
                                >Enter Referral Code</label
                              >
                              <input
                                type="text"
                                formControlName="registerReferralCode"
                                class="form-control"
                              />
                            </div>
                          </div>

                          <div class="col-md-12 mb-3">
                            <div class="form-group">
                              <div
                                class="custom-control custom-checkbox custom-checkbox-lg"
                              >
                                <input
                                  style="width: 1rem; height: 1rem"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="ageCheckbox"
                                />
                                <label
                                  class="custom-control-label"
                                  for="ageCheckbox"
                                  style="
                                    font-size: 20px;
                                    font-weight: 600;
                                    margin-left: 10px;
                                  "
                                >
                                  Are you above 13 years old?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div (click)="submitForm()">
                          <button
                            (click)="openAgeModal('signup')"
                            [disabled]="!form3.valid"
                            class="btn btn-info text-white mt-3 py-3 px-5"
                            style="margin: auto; display: block"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 class="text-white my-3 featurs">Pro-Living Connect</h1>
            <p class="text-white my-3">
              Through Pro-Living Connect, you can effortlessly share, network,
              coach, conduct and share training achievements, interviews,
              industry insights, images..
            </p>
            <div
              class="moretext"
              [ngClass]="{ 'moretext-he': true, 'd-none': !isMoreTextVisible2 }"
            >
              <p class="text-white my-3">
                career milestones for a wide-reaching Pro-Living audience. It's
                the premier destination for Pro-Living individuals and those
                involved in our communities, providing them with a safe space
                exclusively for them.
              </p>
              <p class="text-white my-3">
                Pro-Living individuals and industry professionals can
                reciprocate the support provided by businesses that endorse
                diversity in our community. Business owners now have the
                opportunity to register their companies and connect them with
                specific Pro-Living professionals using unique ID codes during
                the registration process.
              </p>
              <p class="text-white my-3">
                Pro-Living Connect is a groundbreaking social network app
                designed exclusively for the Pro-Living community and businesses
                eager to support the journey of our Pro-Living members as they
                strive for success.
              </p>
              <p class="text-white my-3">
                Share your Business, Portfolio, Achievements, Industry Insights,
                Podcast, Conferences, Testimonials, Videos, Images, and Career
                Highlights for a wide audience to explore. This all-in-one
                platform is essential for community and professionals seeking
                greater visibility using virtual interactive concepts to network
                with others just like you. A safe and positive place to thrive
                in this digital space.
              </p>
            </div>
            <a
              class="moreless-button btn btn-light"
              style="text-decoration: none"
              (click)="toggleMoreText2()"
              >Read more</a
            >
          </div>
        </div>
      </section>
      <!------- tab section end ---------->
      <div class="container-fluid bg">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="program my-5 text-center">
                <h1>Pro Living Programs & Services</h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" id="text1">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/1.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title">The Hero's Project</h4>
                  <div class="mycard">
                    <p class="card-text">
                      The Hero's Project introduces our K-12 module, a
                      comprehensive initiative bringing mental health counseling
                      services and workshops to educational institutions. We
                      extend support to faculty, staff, students, and parents,
                      delivering essential resources for holistic well-being.
                      Our Pro Living course guides students in identifying and
                      embarki
                    </p>
                    <div class="moretext1" style="display: none">
                      <p>
                        on their career paths, while also providing whole-life
                        insurance protection to ensure their future security.
                        Partnering with McAfee, our project incorporates
                        cutting-edge data protection and security measures,
                        offered to schools at no cost. Our commitment is to
                        empower students, faculty, and staff, ensuring every
                        family has access to knowledge and resources for
                        success.
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button1">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" id="text2">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/2.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title">
                    Life after Sports Transitioning Program
                  </h4>
                  <div class="mycard">
                    <p class="card-text">
                      Step into our 'Life After the Game' program—a
                      transformative journey crafted in two sectors. For current
                      or former professional athletes embarking on their
                      post-game voyage, our program offers more than a
                      transition—it's a complete support system. Picture this:
                      salaries, health insurance, and a dedicated accountability
                      group, all tailored
                    </p>
                    <div class="moretext2" style="display: none">
                      <p>
                        to guide and empower you in discovering life's next
                        thrilling phase. Our diverse team, which includes
                        transitioned players, business owners, and seasoned
                        company CEOs, will walk this exciting journey alongside
                        you.
                      </p>
                      <p>
                        If you're a retired athlete or nearing retirement,
                        seeking to make a substantial impact in your community
                        while charting your next steps, Pro Living was crafted
                        with you in mind.
                      </p>
                      <p>
                        The second part of our program is custom-built for
                        collegiate student-athletes whose careers conclude
                        post-college. Our 'Life After the Game' series partners
                        with universities of all sizes, providing tailored
                        support to these athletes as they navigate their
                        post-college lives. We offer a unique platform to learn,
                        network, and prepare for a seamless transition into the
                        workforce.
                      </p>
                      <p>
                        For current and former athletes seeking a purposeful
                        life beyond the game and for colleges invested in their
                        athletes' future success, Pro Living is the empowering
                        partner you've been searching for. Join us in shaping
                        the future beyond sports, where every athlete thrives in
                        their life after the game.
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button2">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" id="text3">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/3.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title">Pro Living Investment Fund</h4>
                  <div class="mycard">
                    <p class="card-text">
                      The Pro Living Investment Fund was strategically crafted
                      for both current and former players seeking to secure a
                      residual income. It's more than an investment—it's an
                      opportunity to support job creation while offering players
                      a secure way to invest their earnings. We carefully test
                      and invest in valuable products that yield returns,
                      empowring
                    </p>

                    <div class="moretext3" style="display: none">
                      players, both current and former, to diversify their
                      investments while championing other professional athletes.
                      <p>
                        Our commitment goes beyond funding; we provide guidance
                        and education to athletes on building generational
                        wealth. We see ourselves as legacy builders, assisting
                        players, both present and past, in creating a lasting
                        impact for themselves and future generations.
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button3">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" id="text4">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/4.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title">Business growth and Development</h4>
                  <div class="mycard">
                    <p class="card-text">
                      Our Business Growth and Development services cater to both
                      for-profit and non-profit companies, facilitating
                      workforce expansion and retention strategies. Our focus on
                      establishing credibility among end-users drives engagement
                      with their services or products, fostering growth and
                      enhancing visibility. With a core mission of fostering
                      company
                    </p>
                    <div class="moretext4" style="display: none">
                      <p>
                        growth, we operate with the goal of enhancing efficiency
                        and aiding in employee retention, ensuring companies
                        optimize their performance and maintain a high level of
                        production.
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button4">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" id="text5">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/5.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title">Mom and Dad's Parenting Club</h4>
                  <div class="mycard">
                    <p class="card-text">
                      Step into our vibrant Mom and Dad's Club—a thrilling space
                      for parents to bond, grow, and learn together! This isn't
                      just a support group; it's a dynamic village where
                      everyday superheroes recharge and thrive. Our innovative
                      engagements spark connections between parents, fostering
                      growth in various life domains.
                    </p>
                    <p class="para">Join us in actively engaging</p>
                    <div class="moretext5" style="display: none">
                      <p>
                        with schools, empowering parental involvement and
                        equipping you with essential tools to elevate not just
                        your own life, but your child's and community's too.
                        This club isn't just for first-timers or seasonal
                        parents; it's an open invitation to all generations!
                        Grandparents bring wisdom, first-timers bring fresh
                        perspectives—it's a melting pot of experiences.
                      </p>
                      <p>
                        It's more than a club; it's a thrilling journey, a safe
                        space for parents to share, connect, and learn from each
                        other's incredible journeys. Get ready to dive into a
                        supportive, exciting community where the bonds you form
                        will be as thrilling as the growth you'll experience!
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button5">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" id="text6">
              <div class="card">
                <img
                  src="assets/aboutimg/olimages/6.png"
                  class="card-img-top img-fluid"
                  alt="..."
                />
                <div class="card-body">
                  <h4 class="card-title" style="display: none">
                    Lipsum generator
                  </h4>
                  <div class="mycard">
                    <p class="card-text" style="display: none">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                    <!-- <p class="para">Join us in actively engaging</p> -->
                    <div class="moretext6" style="display: none">
                      <p>
                        It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsum.
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center d-none"
                    >
                      <i class="fa fa-plus mybtn"></i>
                      <button type="btn" class="btn mybtn moreless-button6">
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <h2 class="text-center mt-3">
            Pro-Living Digital Cards Packages, Products and Platforms
          </h2>
          <div class="col-lg-3 col-md-6 my-4">
            <div class="box1 b">
              <h5>Free Monthly</h5>
              <ul>
                <li>Directory - “Basic” Membership</li>
                <li>Tweet & Talk Access</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 my-4">
            <div class="box1 box2">
              <h5>$5 Monthly</h5>
              <ul>
                <li>Directory - “IAM” Pledge <br />Membership</li>
                <li>Free Standard CRM Integration</li>
                <li>Conference/Podcast Platform</li>
                <li>Eligible For Daily Prize & Money Giveaways</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 my-4">
            <div class="box1 b">
              <h5>$19.99 Monthly</h5>
              <ul>
                <li>
                  “Premium” Membership -Directory, Tweet, Talk &
                  Conference/Podcast
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 my-4">
            <div class="box1 box2">
              <h5>
                Starting @ $109 - Community To Business Branding (Community
                Brands On Your Behalf)
              </h5>
              <ul>
                <li>“Business” Membership - Directory</li>
                <li>50% Monthly goes to Registered “IAM” Pledge Members</li>
                <li>Branded by Community</li>
                <li>Monthly Giveaways (Sponsored By Name Of Your Biz)</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style="
            margin: auto;
            display: table;
            margin-top: 1rem;
            margin-bottom: 4rem;
          "
        >
          <a [routerLink]="['/package']" class="btn btn-dark">Packages</a>
        </div>
      </div>
    </div>

    <!-- Featurs Section End -->

    <!------ contact us ------>
    <section id="contact-us">
      <div class="container">
        <h1>Contact Us</h1>
        <div class="row">
          <div class="col-md-6">
            <div class="get">
              <h5>Get in Touch</h5>
              <div class="form">
                <input type="text" name="" id="" placeholder="Name" /><br />
                <input type="email" name="" id="" placeholder="Email" /><br />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="7"
                  placeholder="Message"
                ></textarea
                ><br />
                <div class="select">
                  <input type="file" name="file" id="file" class="inputfile" />
                  <label for="file"
                    ><i class="fa-solid fa-paperclip"></i>Attach Files</label
                  >
                  <span>Attachments(0)</span>
                </div>
                <button type="submit">SEND MESSAGE</button><br />
                <p class="button-para">
                  This site is protected by reCAPTCHA and the Google
                  <a href="#">Privacy Policy </a>and
                  <a href="#">Terms of Service</a> apply.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="better">
              <h5>Better yet, see us in person!</h5>
              <p>
                Get the quickest response by reaching out to us on our social
                media platforms! You can also contact us directly via phone call
                or text message. We're excited to engage with you and hear your
                thoughts!
              </p>
              <p>
                Drop us a message, and we'll make it a priority to get back to
                you at the earliest convenience.
              </p>
              <button type="button">
                <img
                  src="assets/aboutimg/new-icon-removebg-preview.png"
                  alt=""
                />Message us on WhatsApp
              </button>
              <h6>Pro Living</h6>
              <a href="#">(214)-926-0027</a>
              <a href="#">cmoore@proliving.life</a>
              <h6 class="hrs">Hours</h6>
              <select name="" id="">
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
                <option value="">Open today 09:00 am – 02:00 pm</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!----- subscribe -------->
    <section id="subscribe">
      <div class="container">
        <h2 class="text-center">Subscribe</h2>
        <p class="text-center">
          Get 10% off your first purchase when you sign up for our newsletter!
        </p>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-7">
            <div class="email-form">
              <input type="email" placeholder="Email" />
            </div>
          </div>
          <div class="col-md-2 pb-5">
            <button type="button" class="email-button">SIGN UP</button>
          </div>
        </div>
      </div>
    </section>
    <!----- subscribe end-------->

    <!-- Footer Start -->
    <div
      class="container-fluid bg-dark text-white-50 footer"
      style="background: #68292c !important; height: 100% !important"
    >
      <div class="container py-5">
        <div class="row g-4">
          <div class="col-lg-4 col-md-6">
            <h4 class="text-light"><b>Contact us</b></h4>
            <div class="d-flex flex-column text-start footer-item">
              <a class="btn-link" href="">Privacy Policy</a>
              <a class="btn-link" href="">Refund Policy</a>
              <a class="btn-link" href="">Terms Of Service</a>
              <a class="btn-link" href="">Terms of Sales</a>
              <a class="btn-link" href="">UMYO Profile</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="footer-item">
              <h4 class="text-light mb-3">LET'S CONNECT</h4>
              <p class="mb-4">
                Subscribe to stay up to date on all the latest Pro-Living
                features, products, stories, and more. You can also contact us
                at contact@prolivingbiz.com.
              </p>
              <div class="position-relative mx-auto">
                <input
                  class="form-control border-0 py-2 w-100"
                  type="text"
                  placeholder="Enter Your Email"
                />
                <button
                  type="submit"
                  class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
                  style="top: 0; right: 0"
                >
                  Sent
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="d-flex justify-content-center pt-3">
              <a
                class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
                href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a
                class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
                href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
                href=""
                ><i class="fab fa-instagram"></i
              ></a>
              <a
                class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
                href=""
                ><i class="fab fa-youtube"></i
              ></a>
              <a
                class="btn btn-outline-secondary btn-md-square rounded-circle"
                href=""
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
          <p class="text-white text-center">
            <a href="#" style="text-decoration: none; color: white"
              ><i class="fas fa-copyright text-light me-2"></i>2023
              Pro-Living</a
            >, All right reserved.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- packages modal  -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showPackageModal, 'd-block': showPackageModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Select One Package
        </h5>
        <button type="button" class="close" (click)="closePackageModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: 70vh; overflow-y: scroll">
        <div class="row">
          <div class="col-md-6" *ngFor="let package of packages; let i = index">
            <div class="outerCard" style="min-height: 30vh; cursor: pointer">
              <div
                class="card"
                [ngClass]="{ cardSelect: selectedPackage === package }"
                (click)="selectPackage(package)"
              >
                <div class="card-body">
                  <h5 class="card-title" style="color: rgb(35, 149, 243)">
                    $
                    {{ package.net_price ? package.net_price : package.price }}
                    <sup
                      style="
                        text-decoration: line-through;
                        font-size: 20px;
                        color: gray;
                        padding-left: 15px;
                      "
                      *ngIf="package.net_price"
                      >{{ package.price }}</sup
                    >
                  </h5>
                  <p class="card-text">Cards Limit: {{ package.limit }}</p>
                  <p class="card-duration">Duration: {{ package.expire_in }}</p>

                  <div
                    class="description-container"
                    style="max-height: 100px; overflow-y: auto"
                  >
                    <p class="card-description">{{ package.description }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-100" *ngIf="(i + 1) % 2 === 0"></div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          (click)="closePackageModal()"
          type="button"
          class="btn btn-secondary"
        >
          Close
        </button>

        <div
          *ngIf="selectedPackage?.price != '0'"
          [ngClass]="{
            hidden: selectedPackage == null || selectedPackage?.price == '0',
            paymentButtons: selectedPackage != null
          }"
        >
          <!-- <div id="paypal-button-container"></div> -->
          <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="openStripeModal()"
          >
            Stripe
          </button>
          <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="paypalClick()"
          >
            paypal
          </button>
        </div>
        <div *ngIf="selectedPackage?.price == '0'">
          <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="continuePaymentButton()"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- stripe modal  -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showStripeModal, 'd-block': showStripeModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Pay Through Stripe
        </h5>
        <button type="button" class="close" (click)="closeStripeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <form class="checkout-form" [formGroup]="paymentForm">
            <h3>
              Amount
              {{
                selectedPackage?.net_price
                  ? selectedPackage?.net_price
                  : selectedPackage?.price
              }}
            </h3>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="card-number"
                    >Card Number 14 Digit (Without Dashes)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="card-number"
                    data-stripe-element="cardNumber"
                    formControlName="cardNumber"
                    placeholder="XXXXXXXXXXXXXXXX"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="card-expiry"
                    >Expiration Date in (MM/YY) Format</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="card-expiry"
                    data-stripe-element="expiryDate"
                    formControlName="expiryDate"
                    placeholder="MM/YY"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="card-cvc">CVC 3 Digit</label>
                  <input
                    type="text"
                    class="form-control"
                    id="card-cvc"
                    data-stripe-element="cvc"
                    formControlName="cvc"
                    placeholder="XXX"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  id="pay-button"
                  (click)="pay()"
                  [disabled]="!paymentForm.valid"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeStripeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showAgeModal, 'd-block': showAgeModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <h3>Are You 18+ of your age?</h3>
          <p style="padding: 8px">
            You must be at least 13 years old to use the Service. However,
            children of all ages may use the Service and PLTalk Kids (where
            available) if enabled by a parent or legal guardian. Permission by
            Parent or Guardian If you are under 18, you represent that you have
            your parent or guardian’s permission to use the Service. Please have
            them read this Agreement with you. If you are a parent or legal
            guardian of a user under the age of 18, by allowing your child to
            use the Service, you are subject to the terms of this Agreement and
            responsible for your child’s activity on the Service. You can find
            tools and resources to help you manage your family’s experience on
            PLTalk in our Help Center
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmAge()">
          Yes
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeAgeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showLoadingModal, 'd-block': showLoadingModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <h3>Confirming Payment...</h3>
        </div>
      </div>
    </div>
  </div>
</div>
