<app-admin-nav></app-admin-nav>
<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="outside" *ngIf="records">
  <div class="head">
    <h1>Chat Records</h1>
  </div>
  <div class="table-responsive table-container table-sm mt-4">
    <table class="table">
      <thead>
        <tr>
          <th class="col-1">No.</th>
          <th class="col-3">Name</th>
          <th class="col-6">Email</th>
          <th class="col-2" style="text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of records; let i = index">
          <td>{{ i }}</td>
          <td>{{ record.name }}</td>
          <td>{{ record.email }}</td>

          <td>
            <!-- Show buttons on larger screens -->
            <div class="d-none d-md-block">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                "
              >
                <!-- <a routerLink="/user/{{ record.id }}/edit"> -->
                <a (click)="viewChat(record)">
                  <button class="btn btn-sm btn-primary">View</button>
                </a>
                <button
                  class="btn btn-sm btn-danger"
                  (click)="deleteRecord(record.id)"
                >
                  Delete
                </button>
              </div>
            </div>

            <div class="d-block d-md-none">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                "
              >
                <a (click)="viewChat(record)">
                  <i class="fas fa-eye text-primary mr-2"></i>
                </a>
                <i
                  class="fas fa-trash text-danger"
                  (click)="deleteRecord(record.id)"
                ></i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
