import { Component ,AfterViewInit} from '@angular/core';
declare var $: any; 
@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements AfterViewInit{

  ngAfterViewInit(): void {
    
    
    
  }
}
