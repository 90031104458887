<!-- <nav class="col-md-3 col-lg-3 d-md-block bg-light sidebar"> -->
<div class="position-sticky">
  <ul class="nav flex-column">
    <h3 style="color: #24a0ed; text-align: center" class="mt-3">{{ name }}</h3>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/my-contacts"
        routerLinkActive="active"
      >
        <i class="fas fa-address-book mr-2"></i>My Contacts
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/basic-information"
        routerLinkActive="active"
      >
        <i class="fas fa-user mr-2"></i>Basic Information
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/work-and-social"
        routerLinkActive="active"
      >
        <i class="fas fa-briefcase mr-2"></i>Work and Social
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/apply-for-referral"
        routerLinkActive="active"
      >
        <i class="fas fa-handshake mr-2"></i>Apply for Referral
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/bonus-amount"
        routerLinkActive="active"
      >
        <i class="fas fa-gift mr-2"></i>Bonus Amount
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link custom-nav-link"
        routerLink="/profile/change-password"
        routerLinkActive="active"
      >
        <i class="fas fa-lock mr-2"></i>Change Password
      </a>
    </li>
    <!-- Add links for other profile sections here -->
  </ul>
</div>
<!-- </nav> -->
