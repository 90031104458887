import { Component } from '@angular/core';

@Component({
  selector: 'app-bonus-amount',
  templateUrl: './bonus-amount.component.html',
  styleUrls: ['./bonus-amount.component.css']
})
export class BonusAmountComponent {

  isLoading:boolean=false
}
