<app-nav></app-nav>
<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>

<div class="row topRow">
  <div class="col-md-8 closeBtn"></div>
</div>

<div class="container" *ngIf="!isLoading">
  <div class="top">
    <div class="topLeft">
      <h3 class="heading">Name : {{ name }}</h3>
      <h3 class="email">Email : {{ email }}</h3>
    </div>
    <div class="topRight">
      <button class="btn btn-secondary" (click)="emailChat()">
        Send Chat To Email
      </button>
      <button class="btn btn-secondary" (click)="closeChat()">
        Close Chat
      </button>
    </div>
  </div>
  <div class="row">
    <div class="outsideMsg">
      <div class="chat-messages" #ChatContainer id="ChatContainer">
        <div
          *ngFor="let message of chatMessages"
          class="message-container"
          [ngClass]="{
            'message-sender': message.message_type === 'recieved',
            'message-receiver': message.message_type === 'sent'
          }"
        >
          <div class="message-bubble">
            {{ message.user_message }}
            <div class="message-time">
              {{ message.created_at | date : "shortTime" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-input">
      <input
        [(ngModel)]="newMessage"
        placeholder="Type your message..."
        class="form-control"
      />

      <button (click)="sendMessage()" class="btn btn-primary">Send</button>
    </div>
  </div>
</div>
