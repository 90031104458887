<!-- Spinner Start -->

<!-- Spinner End -->
<!-- Navbar start -->
<section id="header">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a href="index.html" class="navbar-brand"
          ><h1 class="text-white display-6">UMYO</h1></a
        >
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
            <a [routerLink]="['']" class="nav-item nav-link">Home</a>
            <a [routerLink]="['/team']" class="nav-item nav-link">Team</a>
            <a [routerLink]="['/products']" class="nav-item nav-link"
              >Products</a
            >
            <a [routerLink]="['pro']" class="nav-item nav-link">Pro</a>
            <a [routerLink]="['network']" class="nav-item nav-link">Network</a>
          </div>
          <div class="d-flex m-3 ms-auto">
            <p class="text-white" style="margin: 2px 25px; font-size: 25px">
              UMYO
            </p>
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['']">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/team']">Team</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/products']">Products</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/pro']">Pro</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/network']">Network</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->
  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->
</section>
<!-- Hero End -->

<section class="product-grid-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="section-title-left">
          <h2>All Linq Digital Business Products</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/LinqCard_fullycustom_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/LinqCard_logocustom_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/LinqCard_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Tag-black_360x.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Tap-XL-thumbnail-Black-New_360x.gif"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/StretchV2thumbnail-Black-bestseller_bcb1f16f-181e-4bfa-87f8-899e1586f426_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Bracelet-black-front_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/StretchV2thumbnail-Black-bestseller_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Linqstretch_black_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Size_Large_Color_Black_Perspective_Right_Type_Blank_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/Size_Mini_Color_Black_Perspective_Right_Type_Blank_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3">
        <div class="card grid-item">
          <img
            src="assets/images/products/BlackHubV2-linq-right_360x.png"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">
              <a href="#">Linq Card - Fully Custom</a>
            </h5>
            <p class="card-price">from $36.99</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="pagination mt-5 py-5">
          <a href="#" class="prev"><i class="fas fa-angle-left"></i></a>
          <a href="#" class="page">1</a>
          <a href="#" class="page active">2</a>
          <a href="#" class="page">3</a>
          <a href="#" class="next"><i class="fas fa-angle-right"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start -->
<div class="container-fluid bg-dark text-white-50 footer">
  <div class="container py-5">
    <div class="row g-4">
      <div class="col-lg-4">
        <div class="d-flex pt-3">
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="btn btn-outline-secondary btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" href="">Contact us</a>
          <a class="btn-link" href="">Privacy Policy</a>
          <a class="btn-link" href="">Refund Policy</a>
          <a class="btn-link" href="">Terms Of Service</a>
          <a class="btn-link" href="">Terms of Sales</a>
          <a class="btn-link" href="">Data Processing Addendum</a>
          <a class="btn-link" href="">Careers</a>
          <a class="btn-link" href="">Security & Trust Report</a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" href="">UMYO Store</a>
          <a class="btn-link" href="">Industries</a>
          <a class="btn-link" href="">UMYO for Teams</a>
          <a class="btn-link" href="">UMYO Pro</a>
          <a class="btn-link" href="">UMYO Profile</a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="footer-item">
          <h4 class="text-light mb-3">LET'S CONNECT</h4>
          <p class="mb-4">
            Subscribe to stay up to date on all the latest UMYO features,
            products, stories, and more. You can also contact us at
            contact@umyo.com.
          </p>
          <div class="position-relative mx-auto">
            <input
              class="form-control border-0 py-2 w-100"
              type="text"
              placeholder="Enter Your Email"
            />
            <button
              type="submit"
              class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
              style="top: 0; right: 0"
            >
              Sent
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
      <p class="text-white text-center">
        <a href="#" style="text-decoration: none; color: white"
          ><i class="fas fa-copyright text-light me-2"></i> 2023 UMYO</a
        >, All right reserved.
      </p>
    </div>
  </div>
</div>
<!-- Footer End -->

<!-- Back to Top -->
<a
  href="#"
  class="btn btn-dark border-3 border-white rounded-circle back-to-top"
  ><i class="fa fa-arrow-up"></i
></a>
