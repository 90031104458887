<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <div class="container" style="margin-top: 80px; padding: 30px">
    <h1 class="heading">
      <i class="fas fa-check-circle icon"></i>
      Change Password
    </h1>
    <hr class="line" />
    <div class="form-group">
      <label for="oldPassword">Old Password</label>
      <input
        type="password"
        id="oldPassword"
        class="form-control"
        formControlName="oldPassword"
        placeholder="Enter your old password"
      />
      <div
        *ngIf="
          changePasswordForm.get('oldPassword').hasError('required') &&
          (changePasswordForm.get('oldPassword').dirty ||
            changePasswordForm.get('oldPassword').touched)
        "
        style="color: red"
      >
        Old Password is required.
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group mt-3">
        <label for="newPassword">New Password</label>
        <input
          type="password"
          id="newPassword"
          class="form-control"
          formControlName="newPassword"
          placeholder="Enter your new password"
        />
        <div
          *ngIf="
            changePasswordForm.get('newPassword').hasError('required') &&
            (changePasswordForm.get('newPassword').dirty ||
              changePasswordForm.get('newPassword').touched)
          "
          style="color: red"
        >
          New Password is required.
        </div>
        <div
          *ngIf="
            changePasswordForm.get('newPassword').hasError('minlength') &&
            (changePasswordForm.get('newPassword').dirty ||
              changePasswordForm.get('newPassword').touched)
          "
          style="color: red"
        >
          New Password must be at least 6 characters long.
        </div>
      </div>
      <div class="col-md-6 form-group mt-3">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input
          type="password"
          id="confirmNewPassword"
          class="form-control"
          formControlName="confirmNewPassword"
          placeholder="Confirm new password"
        />
        <div
          *ngIf="
            changePasswordForm.get('confirmNewPassword').hasError('required') &&
            (changePasswordForm.get('confirmNewPassword').dirty ||
              changePasswordForm.get('confirmNewPassword').touched)
          "
          style="color: red"
        >
          Confirm New Password is required.
        </div>
        <div
          *ngIf="
            changePasswordForm
              .get('confirmNewPassword')
              .hasError('minlength') &&
            (changePasswordForm.get('confirmNewPassword').dirty ||
              changePasswordForm.get('confirmNewPassword').touched)
          "
          style="color: red"
        >
          Confirm New Password must be at least 6 characters long.
        </div>
        <div
          *ngIf="
            changePasswordForm.get('confirmNewPassword').value !==
              changePasswordForm.get('newPassword').value &&
            (changePasswordForm.get('confirmNewPassword').dirty ||
              changePasswordForm.get('confirmNewPassword').touched)
          "
          style="color: red"
        >
          Confirm New Password must match New Password.
        </div>
      </div>
    </div>

    <button
      class="btn btn-primary rounded-corner mt-3"
      [disabled]="!changePasswordForm.valid"
    >
      Update Password
    </button>
    <h4 class="heading2" style="text-align: left">
      <i class="fas fa-check-circle icon"></i>
      Package Subscription
    </h4>

    <button
      class="btn btn-primary rounded-corner mt-3"
      style="display: flex; align-items: center; justify-content: left"
      (click)="cancelSubscription()"
    >
      Cancel Subscription
    </button>
  </div>
</form>
