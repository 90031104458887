<app-admin-nav></app-admin-nav>

<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>
<div class="container" *ngIf="!isLoading">
  <h3 class="heading">Name : {{ name }}</h3>
  <div class="row">
    <div class="outsideMsg">
      <div class="chat-messages" id="chat-messages">
        <div
          *ngFor="let message of chatMessages"
          class="message-container"
          [ngClass]="{
            'message-sender': message.message_type === 'sent',
            'message-receiver': message.message_type === 'recieved'
          }"
        >
          <div class="message-bubble">
            {{ message.user_message }}
            <div class="message-time">
              {{ message.created_at | date : "shortTime" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
