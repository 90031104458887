<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>

<div
  class="container"
  *ngIf="!referralAvailable && !isLoading"
  style="padding: 30px; margin-bottom: 60px"
>
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Apply for referral
  </h1>

  <form (submit)="saveChanges()" style="margin-top: 50px">
    <div class="row" style="margin-top: 50px">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary btn-rounded">
          Send Request
        </button>
      </div>
    </div>
  </form>
</div>
<div
  class="container"
  *ngIf="referralAvailable && !isLoading"
  style="padding: 30px; margin-bottom: 60px"
>
  <h1 class="heading">
    <i class="fas fa-check-circle icon"></i>
    Referral Code
  </h1>

  <form (submit)="sendpaymentInfo()" style="margin-top: 50px">
    <div class="row" style="margin-top: 50px">
      <h5 style="text-align: left">
        Your Referral Code:
        <span style="color: #24a0ed">{{ userData.Users.refer_code }}</span>
      </h5>
      <h5 style="text-align: left">
        Your Total Referrals:
        <span style="color: #24a0ed">{{ userData.totalReffers }}</span>
      </h5>
      <div class="row" style="margin-top: 50px">
        <div class="form-group">
          <label for="paymentId">Payment ID</label>
          <input
            type="email"
            id="paymentId"
            placeholder="Account ID"
            [(ngModel)]="paymentId"
            name="paymentId"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label for="paymentType">Payment Type</label>
          <select
            id="paymentType"
            [(ngModel)]="paymentType"
            name="paymentType"
            class="form-control"
          >
            <option value="" disabled selected>Please Select</option>
            <option value="Paypal">Paypal</option>
            <option value="Stripe">Stripe</option>
          </select>
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 50px">
        <button type="submit" class="btn btn-primary btn-rounded">
          Send Payment Info
        </button>
      </div>
    </div>
  </form>
</div>
